import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

// Layout Blueprints

// Example Pages
const PaginaPrincipal = lazy(() => import('./pages/paginaPrincipal/Principal'));
const PaginaInicial = lazy(() => import('./pages/paginaInicial/Inicial'));
const PaginaNegociacao = lazy(() =>
  import('./pages/paginaNegociacao/Negociacao')
);

const Routes = () => {
  const location = useLocation();

  return (
    <AnimatePresence>
      <Suspense
        fallback={
          <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
            <div className="w-50 mx-auto">Carregando...</div>
          </div>
        }>
        <Switch>
          <Redirect exact from="/" to="/PaginaInicial" />
          <Route path={['/PaginaInicial']}>
            <Switch location={location} key={location.pathname}>
              <motion.div initial="initial" animate="in" exit="out">
                <Route path="/PaginaInicial" component={PaginaInicial} />
              </motion.div>
            </Switch>
          </Route>

          <Route path={['/principal/:id']}>
            <Switch location={location} key={location.pathname}>
              <motion.div initial="initial" animate="in" exit="out">
                <Route path="/principal/:id" component={PaginaPrincipal} />
              </motion.div>
            </Switch>
          </Route>
          <Route path={['/negociacao/:id']}>
            <Switch location={location} key={location.pathname}>
              <motion.div initial="initial" animate="in" exit="out">
                <Route path="/negociacao/:id" component={PaginaNegociacao} />
              </motion.div>
            </Switch>
          </Route>
        </Switch>
      </Suspense>
    </AnimatePresence>
  );
};

export default Routes;
